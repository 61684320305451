import React from 'react';
import { Link } from "gatsby"

import Card from '../Card/Card'

import './styles.scss';

import card_1 from "../../images/card-1.jpg"
import card_2 from "../../images/card-2.jpg"

const GetKnow = () => {
    return (
        <>
            <section className="container-fluid get-know" id="get-know">
                <h2 className="text-center title">Γνωρίστε το nowCaptain</h2>
                <p className="text-center paragraph">Ανακαλύψτε τις απρόσιτες, κρυμμένες παραλίες και νησιά στην <span className="blue-text">Ελλάδα</span>. <br /> Κάνουμε το beaches hopping & islands hopping πιο εύκολα προσβάσιμα.</p>
                <div className="row">
                    {/*{window.innerWidth <= 991 ? "col-md-6" : "col-md-6 d-flex justify-content-center"}*/}
                    <div className="col-md-6 ">
                        <div className="card-left-side h-100">
                            <Card img={card_1} title="Εξερευνήστε μέρη" description="Κρυμμένες, απρόσιτες παραλίες. Εξατομικεύστε το ιδιωτικό σας ταξίδι για τους φίλους και την οικογένειά σας στο χρονοδιάγραμμα σας." />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card-right-side h-100">
                            <Card img={card_2} title="Απολαύστε εμπειρίες" description="Γνωρίστε τα τοπικά μέρη μαζί με τους φίλους και την οικογένειά σας μέσω κολύμβησης, καταδύσεων, κολύμβησης με αναπνευστήρα, κάμπινγκ κ.λπ. Μάθετε για τον τοπικό πολιτισμό από τους τοπικούς καπετάνιους." />
                        </div>
                    </div>
                </div>
                <div className="text-center paragraph-bottom">
                    <p>Σας ενδιαφέρει το <span className="blue-text">nowCaptain</span>; <br />Επικοινωνήστε μαζί μας</p>
                </div>
            </section>
        </>
    )
}

export default GetKnow;