import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Link, animateScroll as scroll } from "react-scroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import GetKnow from "../GetKnow/GetKnow";
import "./styles.scss";

import hero_video from '../../../static/hero_video.mp4';

import Typical from 'react-typical'

// import BackgroundImage from 'gatsby-background-image'

// const BackgroundSection = () => {
//   return (
//     <StaticQuery
//       query={graphql`
//       query {
//         indexImage: file(relativePath: { eq: "images/hero_img.jpg" }) {
//           childImageSharp {
//             fluid(quality: 90, maxWidth: 1920) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     `}
//       render={data => {
//         // Set ImageData.
//         const imageData = data.indexImage.childImageSharp.fluid
//         return (
//           <div>
//             {/* // <BackgroundImage
//           //   Tag="section"
//           //   className="hero_img"
//           //   fluid={imageData}
//           //   backgroundColor={`#040e18`}
//           // > */}
//             <video className='' autoPlay loop muted>
//               <source src={hero_video} type='video/mp4' />
//             </video>
//               <div className="hero-darken">
//                 <h2 className="hero-text">
//                   <p>A unique way to explore hidden</p>
//                   <Typical
//                     steps={['beaches.', 1500, 'islands.', 1500, 'caves.', 1500]}
//                     loop={Infinity}
//                     wrapper="p"
//                   />
//                 </h2>
//               </div>
//           </div>
//           // </BackgroundImage>
//         )
//       }}
//     />
//   )
// }


// export default BackgroundSection

const bg = () => {
  return (
    <div className="main">
      <video class="hero-video" autoPlay="autoplay" loop="loop" muted>
          <source src={hero_video} type="video/mp4" />
        </video>

      <div className="hero-darken">
        <div className="content">
          <h2 className="hero-text">
            <p>Ένας μοναδικός τρόπος εξερεύνησης κρυφών</p>
            <Typical
              steps={['παραλίων.', 1500, 'νησιών.', 1500, 'σπηλίων.', 1500]}
              loop={Infinity}
              wrapper="p"
            />
          </h2>
          <Link class="btn btn-learn-more" type="button" to="get-know" spy={false} smooth={true} offset={-60} duration={600}>Μαθε περισσοτερα</Link>
        </div>
        </div>
    </div>
  )
}

export default bg