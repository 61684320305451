import React, { useEffect, Component } from "react";
import { graphql, useStaticQuery } from 'gatsby'

import "./styles.scss"
import Hero from "../Components/HeroImage/Hero"
import GetKnow from "../Components/GetKnow/GetKnow"
import How from "../Components/HowItWorks/HowItWorks"
import Contact from "../Components/Contact/Contact"
import Footer from "../Components/Footer/Footer"


import Layout from "../Components/layout"
// import SEO from "../components/seo"

const IndexPage = (props) => {
  // console.log(props);
  return(
    <div>
      <Layout>
        <Hero/>
        <GetKnow/>
        <How/>
        <Contact/>
        <Footer/>
      </Layout>
    </div>
  )
}

export default IndexPage

