import React from 'react';
import { Link } from "gatsby"

import CardBox from '../Card/Card'
import './styles.scss';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { isMobileOnly } from 'react-device-detect';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import step_1 from "../../images/step_1.png"
import step_2 from "../../images/step_2.png"
import step_3 from "../../images/step_3.png"

SwiperCore.use([Pagination, A11y]);

const HowItWorks = () => {
    let width_num = 100
    return (
        <>
            <section className="container-fluid how-it-works" id="how-works">
                <h2 className="text-center title">Πώς λειτουργεί;</h2>
                <p className="text-center paragraph">Ανακαλύψτε περισσότερα νέα μέρη χρησιμοποιώντας το smartphone σας με 3 απλά βήματα.</p>
                {/*{true ?*/}
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-3 d-flex justify-content-center">
                            <CardBox isHow={true} img={step_1} width={width_num} title="1. Επιλέξτε τον προορισμό" description="Ανοίξτε την εφαρμογή και επιλέξτε τον προορισμό σας." />
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <CardBox isHow={true} img={step_2} width={width_num} title="2. Επιλέξτε ένα σκάφος" description="Επιλέξτε ένα σκάφος που σας ταιριάζει. (π.χ. αριθμός επιβατών, τιμή, βαθμολογία)." />
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <CardBox isHow={true} img={step_3} width={width_num} title="3. Ανεβείτε στο σκάφος" description="Συναντήστε τον καπετάνιο και απολαύστε τη θάλασσα." />
                        </div>
                    </div>
              {/*:*/}
              {/*      <div className="row d-flex justify-content-center">*/}
              {/*          <Swiper*/}
              {/*              spaceBetween={30}*/}
              {/*              slidesPerView={1}*/}
              {/*              navigation*/}
              {/*              pagination={{ clickable: false }}*/}
              {/*              scrollbar={{ draggable: false }}>*/}
              {/*              <SwiperSlide>*/}
              {/*                  <div className="d-flex justify-content-center">*/}
              {/*                      <CardBox isHow={true} img={step_1} width={width_num} title="1. Επιλέξτε τον προορισμό" description="Ανοίξτε την εφαρμογή και επιλέξτε τον προορισμό σας." />*/}
              {/*                  </div>*/}
              {/*              </SwiperSlide>*/}
              {/*              <SwiperSlide>*/}
              {/*                  <div className="d-flex justify-content-center">*/}
              {/*                      <CardBox isHow={true} img={step_2} width={width_num} title="2. Επιλέξτε ένα σκάφος" description="Επιλέξτε ένα σκάφος που σας ταιριάζει. (π.χ. αριθμός επιβατών, τιμή, βαθμολογία)." />*/}
              {/*                  </div>*/}
              {/*              </SwiperSlide>*/}
              {/*              <SwiperSlide>*/}
              {/*                  <div className="d-flex justify-content-center">*/}
              {/*                      <CardBox isHow={true} img={step_3} width={width_num} title="3. Ανεβείτε στο σκάφος" description="Συναντήστε τον καπετάνιο και απολαύστε τη θάλασσα." />*/}
              {/*                  </div>*/}
              {/*              </SwiperSlide>*/}
              {/*          </Swiper>*/}
              {/*      </div>}*/}
            </section>
        </>
    )
}

export default HowItWorks;