import React from 'react';
import { Link } from "gatsby"

import './styles.scss';

import Form from '../Form/Form'

import logo_hat_blue from '../../Assets/logo_hat_blue.svg';

const Contact = () => {
    return (
        <>
            <section className="contact" id="contact">
                <h2 className="text-center title">Επικοινωνήστε μαζί μας</h2>
                <div className="row">
                    <div className="col-md-6 left-side">
                        {/*<p className="text-center paragraph-left-side">Αφήστε μας μια γραμμή</p>*/}
                        <Form />
                    </div>
                    <div className="col-md-6 right-side">
                        <div className="right-side">
                            <img className="d-flex justify-content-center mx-auto center-block logo-hat" src={logo_hat_blue} />
                            <p className="text-center paragraph-right-side">Παρακαλούμε επικοινωνήστε μαζί μας αν έχετε απορίες και ένα μέλος της ομάδας <span className="blue-text">nowCaptain</span> θα χαρεί να σας απαντήσει.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;