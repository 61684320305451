import React, { useState, useEffect } from "react"
import axios from "axios";
import { Link } from "gatsby"


import './styles.scss';

const FormOk = () => {
    return (
        <>
            <div class="alert alert-success m-3" role="alert">
                Το έχουμε! Ένα μέλος της ομάδας μας θα επικοινωνήσει μαζί σας σύντομα!
            </div>
        </>
    )
}

const FormError = () => {
    return (
        <>
            <div class="alert alert-warning m-3" role="alert">
                Χμμ.. Κάτι πήγε στραβά με τη φόρμα αποστολής.<br/> Παρακαλούμε επικοινωνήστε με τη διεύθυνση email info@nowcaptain.com.
            </div>
        </>
    )
}

const Form = () => {
    const [serverState, setServerState] = useState({
        submitting: null,
        status: null
    });
    const handleServerResponse = (ok, form) => {
        setServerState({
            submitting: null,
            status: ok
        });
        if (ok) {
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/1db7a86a-0f42-4a2a-9570-fe7e7c12bc1b",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, form);
            })
            .catch(r => {
                handleServerResponse(false, form);
            });
    };
    
    return (
        <> 
            <form className="contact-form" onSubmit={handleOnSubmit}>
                {serverState.status === true ? <FormOk /> : null}
                {serverState.status === false ? <FormError /> : null}
                <div className="form-group">
                    <label className="label-title" for="Name" required="required">Ονομα:</label>
                    <input type="text" name="name" className="form-control" id="name" placeholder="Το όνομα σου" required="required"/>
                </div>
                <div className="form-group">
                    <label className="label-title" for="Email">Email:</label>
                    <input type="email" name="email" className="form-control" aria-describedby="emailHelp" id="exampleInputName" placeholder="Το email σου" required="required"/>
                </div>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck" name="captain" />
                    <label class="custom-control-label captain_label" for="customCheck">Είμαι καπετάνιος:</label>
                    <small id="emailHelp" class="form-text text-muted">Σας ενδιαφέρει να γίνετε συνεργάτης, επιλέξτε το παραπάνω πλαίσιο ελέγχου.</small>
                </div>
                <div class="form-group">
                    <label className="label-title" for="exampleFormControlTextarea1">Μήνυμα:</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" name="message" placeholder="Πληκτρολογήστε το μήνυμά σας" rows="4"></textarea>
                </div>
                <button type="submit" className="btn" disabled={serverState.submitting}>
                    Υποβολη
                </button>
            </form>
        </>
    );
}

export default Form;